<template>
    <div class="container">
        <!-- 大会报告 -->
        <div class="center">
            <aside-part></aside-part>
            <div class="content">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>大会报告</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="title">
                    <div class="title_zh">大会报告</div>
                    <div class="title_en">plenary session</div>
                </div>
                <div class="box">
                    <img src="@/assets/ing.png" alt="">
                    <p>内容更新中...</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import asidePart from '@/components/asidePart.vue';
export default {
    name: 'report',
    components: {
        asidePart
    },
}
</script>

<style lang="less" scoped>
.center {
    display: flex;
    align-items: top;
    flex-shrink: 0;

    .content {
        padding: 30px;
        width: calc(100% - 60px);

        .title {
            margin-top: 20px;
        }

        .box {
            width: 100%;
            height: 500px;
            text-align: center;
            font-size: 16px;
            color: #0b88e0;
            opacity: 0.5;

            img {
                width: 300px;
            }
        }
    }
}
</style>